.form__control {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  background-color: white;
  border-radius: $ui-border-radius;
  overflow: hidden;
  input {
    flex: 1;
    border: none;
    &:focus {
      outline: transparent;
      outline-color: transparent;
    }
  }
  > * {
    margin: 0;
    padding: 2rem;
    align-self: center;
    // background-color: white;
    border: none;
  }
  > label:first-child {
    // background-color: $devbg2;
    font-weight: 600;
    text-transform: uppercase;
    color: black;
  }
  > i:last-child {
    padding: 1rem 2rem;
    font-size: 3rem;
    // transform: rotate(90deg);
    // background-color: $devbg3;
    color: $primary-color;
  }
}

.purchase_form_controll_phone {
  display: flex;
  margin: 0;
  border: 1px solid #f2f2f2;
  padding: 0;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: $ui-border-radius;
  @media only screen and (max-width: 600px) {
    margin-bottom: 1rem;
  }
}
.purchase_form_controll {
  display: flex;
  margin: 0;
  border: 1px solid #f2f2f2;
  padding: 0;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-radius: $ui-border-radius;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    margin-bottom: 1rem;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: #0a373d;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #0a373d;
  }

  ::placeholder {
    color: #0a373d;
  }
  input {
    flex: 1;
    border: none;
    &:focus {
      outline: transparent;
      outline-color: transparent;
    }
  }
  > * {
    margin: 0;
    padding: 2rem;
    align-self: center;
    // background-color: white;
    border: none;
  }
  > label:first-child {
    // background-color: $devbg2;
    font-weight: 600;
    text-transform: uppercase;
    color: black;
  }
  > i:last-child {
    padding: 1rem 2rem;
    font-size: 3rem;
    // transform: rotate(90deg);
    // background-color: $devbg3;
    color: $primary-color;
  }
}

.invisible_field {
  visibility: hidden;
}

.form-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
  > * {
    flex: 1;
  }
  > *:first-child {
    @media only screen and (min-width: 600px) {
      margin-right: 1rem;
    }
  }
  > *:last-child {
    @media only screen and (min-width: 600px) {
      margin-left: 1rem;
    }
  }
}
.pay-button {
  background-color: #ffcc89;
}
.form__control--underlined {
  border-bottom: 1px solid $primary-color;
  border-radius: 0;
  background-color: transparent;
  > * {
    padding: 1rem 0;
    font-size: 1.4rem;
    background-color: transparent;
    color: white;
  }
  > *::placeholder {
    color: $primary-color;
    text-transform: uppercase;
  }
}
.form__control--inverse {
  border-color: white;
  > *::placeholder {
    color: white;
  }
}
