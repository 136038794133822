.sliderbox {
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: white;
  border: 1px solid $border-color;

  @media screen and (min-width: $media-breakpoint-1024px) {
    margin: $section-spacing;
  }
}
.sliderbox__header {
  padding: 2rem 0 0;
  text-align: center;
}
.sliderbox__wrapper {
  display: flex;
  padding: 4rem 0;
  justify-content: center;@media screen and (min-width: $media-breakpoint-900px) {
    justify-content: space-between;
  }
}
.sliderbox__nav {
  padding: 0 2rem;
  justify-content: center;
  align-self: center;
  text-align: center;

  > i {
    font-size: 5rem;
    color: $primary-color;
  }
}
.sliderbox__items {
  flex: 1;
  justify-content: space-between;
}
.sliderbox__item {
  > div {
    flex: 1;
  }
  @media screen and (min-width: $media-breakpoint-900px) {
    padding: 0 4rem;
  }
}
.sliderbox__item > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: $media-breakpoint-1024px) {
    flex-direction: row;
    justify-content: space-between;;
    margin-right: 8rem;
  }
}
.sliderbox__thumb {
  width: 200px;
  margin-bottom: 4rem;
  @media screen and (min-width: $media-breakpoint-1024px) {
    width: 400px;
    margin-right: 8rem;
    margin-bottom: 0;
  }
}
.sliderbox__content h1 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 600;
}
.sliderbox__content p {
  font-size: 1.4rem;
  font-weight: 400;
}
