// Media Breakpoints
$media-breakpoint-375px: 375px;
$media-breakpoint-500px: 500px;
$media-breakpoint-600px: 600px;
$media-breakpoint-700px: 700px;
$media-breakpoint-800px: 800px;
$media-breakpoint-900px: 900px;
$media-breakpoint-1024px: 1024px;
$media-breakpoint-1100px: 1100px;
$media-breakpoint-1200px: 1200px;
$media-breakpoint-1300px: 1300px;

// Layout settings
$max-width: 100rem;
$section-spacing: 10rem auto 15rem;
$blockquote-padding: 10rem 7rem;

// $primary-color: #294f76;
$primary-color: #0A373D;
$secondary-color: #245157;
$contrast-color: #FFCC89;

$font-family: 'Open Sans', sans-serif;
$reset-font-size: 62.5%; // 16px * 0.625 = 10px
$title-color: black;
$text-color: #888;
$text-color-light: lighten($text-color, 20%);
$text-color-dark: darken($text-color, 20%);

// $page-background-color: #f2f2f2 url(assets/bg_noise_white_2.png) repeat;
$page-background-color: #f2f2f2;

$border-color:#e6e6e6;
$line-color: #b2b2b2;
$line-color-light: #aaa;

$ui-border-radius: 10px;

// Dev Settings
$devbg1: rgba(100,255,255,.5);
$devbg2: rgba(255,100,255,.5);
$devbg3: rgba(255,255,100,.5);

@import './base/base';
@import './base/icons';
@import './base/layout';
@import './base/menus';
@import './base/typography';
@import './components/button';
@import './components/blockquote';
@import './components/collapsible';
@import './components/content-block';
@import './components/footer-two';
@import './components/form';
@import './components/header';
@import './components/material-icons';
@import './components/partnership';
@import './components/partners';
@import './components/payment-feedback';
@import './components/process-steps';
@import './components/risika-kreditscore';
@import './components/search-result';
@import './components/search-results';
@import './components/section-credit-rating';
@import './components/sliderbox';
@import './components/stripe';
@import './components/testimonials';
@import './components/topbar';

// Master helper styles
.background-primary {
  background-color: $primary-color;
}
.hidden {
  display: none;
}
.rotate-90 {
  transform: rotate(90deg);
}
.clickable {
  cursor: pointer;
}

// Make logo Datatilsynet smaller
#datatilsynet {
  .content-block__left {
    max-width: 20rem;
  }
  img {
    margin: 0 auto;
    max-width: 36rem;
  }
  p {
    // margin: 0 auto;
    // max-width: 44rem;
    // text-align: left;
  }
}

// Collapsible`stying
.collapsible__content {
  max-width: $max-width;
}

.color--white {
  color: white;
}
.color--blue {
  color: #294f76;
}
.color--red {
  color: red;
}
.rotate--90 {
  transform: rotate(90deg);
}
.rotate--180 {
  transform: rotate(180deg);
}
.flip {
  transform: scaleX(-1);
}

.stripe-test {
  margin: 0 auto;
  max-width: 600px;
}
