.topbar {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  justify-content: space-between;
  background-color: $primary-color;
  color: white;
  z-index: 100;
  @media screen and (min-width: $media-breakpoint-900px) {
    flex-direction: row;
    padding-top: 1rem;
    padding-left: 0;
    padding-right: 0;
  }
}
.topbar__brand {
  display: flex;
  padding: 3rem;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: $media-breakpoint-900px) {
    border-right: 2px solid white;
  }

  img {
    width: 100px;
  }
}
.topbar__title {
  @media screen and (min-width: $media-breakpoint-500px) {
    margin: 0 5rem;
  }
  @media screen and (min-width: $media-breakpoint-900px) {
    margin: 0 2rem;
  }
  @media screen and (min-width: $media-breakpoint-1200px) {
    max-width: 200px;
  }

  h2 {
    font-weight: 600;
    line-height: 3rem;
    color: white;
  }
}
.topbar__body {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: $media-breakpoint-900px) {
    align-items: flex-start;
  }
  @media screen and (min-width: $media-breakpoint-1200px) {
    flex-direction: row;
    align-items: center;
  }
}
.topbar__content {
  flex: 1;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  // background-color: $devbg2;

  > div {
    flex: 1;
  }
  .form__control {
    margin: 1rem 0;
  }
  @media screen and (min-width: $media-breakpoint-500px) {
    justify-content: flex-start;
  }
  @media screen and (min-width: $media-breakpoint-900px) {
    text-align: left;
    .form__control {
      margin: 1.5rem 2rem;
    }
    .mui-container {

    }
  }
}
.topbar__content__feedback {
  margin: 2rem;
  text-align: left;
  * { color: white; }
}
.topbar__content__column-data {
  display: inline-block;
  margin: 2rem;
  text-align: left;

  // background-color: $devbg1;
}
.topbar__product-price {
  display: flex;
  margin: 1rem auto;
  padding: 2rem 2rem;
  max-width: 500px;
  align-items: center;
  border: 1px dashed white;
  > * { color: white; }
  @media screen and (min-width: $media-breakpoint-900px) {
    margin: 0 2rem;
    padding: 0 2rem;
  }
}
.topbar__content__column-data label,
.topbar__product-price label {
  // margin-right: 3rem;
  max-width: 80px;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: white;
}
.topbar__content__column-data h3 {
  color: white;
}
.topbar__controls {
  display: flex;
  margin: 1rem auto;
  align-items: center;

  button {
    margin: 0 1rem;
  }

  @media screen and (min-width: $media-breakpoint-700px) {
    justify-content: center;
    align-items: center;
    padding-right: 1rem;
  }
  @media screen and (min-width: $media-breakpoint-900px) {
    flex-direction: column;
    align-items: flex-end;
    button:first-child {
      margin-bottom: 1rem;
    }
  }
  @media screen and (min-width: $media-breakpoint-1300px) {
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
    // padding-top: 4rem;
    padding-right: 3rem;
    > button:first-child {
      margin-bottom: 0;
    }
    > button:last-child {
      margin-left: 1rem;
    }
  }

  .mui-container {
    border-color: white;
  }
  .mui-container i {
    color: white;
  }
}

@media screen and (min-width: $media-breakpoint-900px) {
  .state--new_search .mui-container,
  .state--show_search_results .mui-container {
    margin-top: 4rem;
  }
}
@media screen and (min-width: $media-breakpoint-1200px) {
  .state--new_search .mui-container,
  .state--show_search_results .mui-container {
    margin-top: 0;
  }
}
.loader-override {
  > div {
    width: 100%;
    background-color: darken($primary-color, 10%);
    > div:first-child {
      background-color: orange;
    }
    > div:last-child {
      background: transparent;
    }
  }
}
