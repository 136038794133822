.content-block {
  @media screen and (min-width: $media-breakpoint-900px) {
    display: flex;
  }
}
.content-block__left {
  margin-bottom: 2rem;
  @media screen and (min-width: $media-breakpoint-900px) {
    flex: 1;
    max-width: 25%;
    margin-right: 15rem;
    margin-bottom: 0;
  }
}
.content-block__center {
  flex: 1;
  margin: 0 auto;
}
.content-block__right {
  flex: 2;
  margin-top: 4rem;
  @media screen and (min-width: $media-breakpoint-900px) {
    margin-top: 0;
  }
}
.content-block h1 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 5rem;
}
