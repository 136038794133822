#section-credit-rating {
  .accordtion__item:first-child {}

  .risika-kreditscore {
    flex-direction: column;
    @media screen and (min-width: $media-breakpoint-500px) {
      flex-direction: row;
    }
  }
  .risika-kreditscore__score {
    margin-bottom: 2rem;
    @media screen and (min-width: $media-breakpoint-500px) {
      margin-bottom: 0;
    }
  }
}
