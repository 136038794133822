html {
  background-color: #313131;
}
body {
  background-color: $page-background-color;
}
html,
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
ul, li {
  margin: 0;
  padding: 0;
}
input, textarea, select, button {
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  margin: 0em;
}
img {
  width: 100%;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
