// Basic layout utilities
// --------------------------------------
.max-width {
  margin: 0 auto;
  max-width: $max-width;
  > * {
    padding: 2rem;
  }
}
.full-width {
  width: 100%;
}
section {
  margin: 8rem auto;
  @media screen and (min-width: $media-breakpoint-900px) {
    margin: $section-spacing;
  }
}

// Flexbox layout utilities
// --------------------------------------
.flex,
.display-flex {
  display: flex;
  > * {
    flex: 1;
  }
}

// Text & content utilities
// --------------------------------------
.centered {
  text-align: center;
}

// Visual layout elements
// --------------------------------------
.line {
  width: 100%;
  height: 1px;
  border: none;
  border-bottom: 1px solid black;
}
