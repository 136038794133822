#search-results {
  .collapsible__panel {
    // min-height: 90vh;
    // background-color: rgba(16,31,46,0.4);
    background: transparent;
    box-shadow: none;
  }
  .collapsible__body {
    margin: 5rem auto 0;
    max-width: 600px;
  }
  .collapsible__controls {
    margin: 0 auto;
    max-width: 600px;
    background-color: $primary-color;
  }

  h2 {
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
    padding: 3rem 2rem;
    background-color: $primary-color;
    color: white;
    .search-label {
      font-style: italic;
      font-weight: 300;
    }
    .search-keyword {
      flex: 1;
      font-weight: 700;
      letter-spacing: 0.2rem;
    }
    .search-new {
      font-size: 1.2rem;
      cursor: pointer;
      // border-bottom: 1px dotted white;
      text-decoration: underline;
      color: rgba(255,255,255,0.8);
      &:hover { color: white; }
    }
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li:nth-child(2n+1) {
    background-color: $secondary-color;
  }
  li:nth-child(2n) {
    background-color: lighten($secondary-color, 5%);
  }
  li > div {
    width: 100%;
    > div:first-child {
      background-color: orange;
    }
    > div:last-child {
      background: transparent;
    }
  }
  .search-result__name {
    flex: 1;
  }
  .search-result__cvr {
    display: flex;
    align-items: center;
  }
  a {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1rem;
    font-weight: 300;
    color: darken(white, 5%);
    cursor: pointer;
    &:hover {
      background-color: rgba(255,255,255,0.1);
    }
  }
  span {
    padding: 0.5rem 1rem;
  }
}
