.form {
  
}
.card {
  background: #F6F9FC;
  padding: 3rem;
  border-radius: .25rem;
}
.cardElement {
  background-color: white;
  border: 1px solid lightgray;
  height: 4rem;
  padding-left: 1rem;
  padding-top: 1rem;
  border-radius: .25rem;
}
.payButton:disabled{
  button[disabled]{
    background-color: blue
  }
}
.inputField{
  width: 100%;
  margin-bottom: 2rem;
  padding: 1rem;
}
.expiration { 
  width: 13.2rem;
}
.cvc {
  width: 8.3rem;
}
.number {
  width: 28.4rem;
}
.stripeLogo {
  width: auto;
  margin-bottom: -2rem;
}
.risikaLogo {
  width: 16rem;
  margin: 0 auto;
  display: block;
  margin-bottom: 2rem;
}