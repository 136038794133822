blockquote {
  display: block;
  position: relative;
  margin: 0 auto;
  padding: $blockquote-padding;
  width: 100%;
  border-top: 1px solid $line-color;
  border-bottom: 1px solid $line-color;
  font-size: 3.5rem;
  font-weight: 300;
  font-style: italic;
  color: $text-color-dark;

  // background: $devbg1;

  > p {
    display: inline-block;
    position: relative;
    line-height: 4rem;

    // background: $devbg2;
  }
  &:before,
  &:after {
    display: block;
    position: absolute;
    content: '"';
    font-size: 7rem;
    color: black;
  }
  &:before {
    top: 3rem;
    left: 3rem;
    transform: rotate(180deg);
  }
  &:after {
    bottom: 3rem;
    right: 3rem;
  }
}
