.material-icons {
  opacity: 0.7;
}

[aria-expanded='false'] .material-icons {
  transform: rotate(360deg);
  transition: all .3s;
  // color: green;
}
[aria-expanded='true'] .material-icons {
  transform: rotate(225deg);
  transition: all .3s;
  // color: red;
  // opacity: 0.5;
}
