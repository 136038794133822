.testimonials {
  margin: 0 2rem;
  border: 1px solid $border-color;
  border-radius: 6px;
  overflow: hidden;
  background-color: white;

  .slider-wrapper {
  }

  .slide {
    background-color: white;

  }
  .testimonial {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    // background-color: $devbg1;
    @media screen and (min-width: $media-breakpoint-800px) {
      flex-direction: row;
    }
  }
  .testimonial__thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    overflow: hidden;
    img { border-radius: 50%; }
    @media screen and (min-width: $media-breakpoint-800px) {
      padding: 2rem;
      min-width: 200px;
    }
    // background-color: $devbg2;
  }
  .testimonial__copy {
    display: flex;
    padding: 2rem;
    padding-left: 4rem;
    flex-direction: column;
    justify-content: center;
    font-size: 1.4rem;
    text-align: left;
    // background-color: $devbg3;
  }
}
.carousel.carousel-slider .control-arrow {}
.carousel.carousel-slider .control-arrow:hover {
  background: none;
}
.carousel.carousel-slider .control-prev,
.carousel.carousel-slider .control-next {
  padding: 0 1rem;
}
.carousel .control-prev.control-arrow {
  &:before {
    border-right: 12px solid $primary-color;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }
}
.carousel .control-next.control-arrow {
  &:before {
    border-left: 12px solid $primary-color;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
  }
}
