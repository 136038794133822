.collapsible {
  position: relative;
  width: 100%;
}
.collapsible__panel {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: $page-background-color;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);

  transition: all 0.2s;
}
.collapsible__content {
  margin: 0 auto;
  padding: 0 2rem;
  @media screen and (min-width: $media-breakpoint-1024px) {
    padding: 0;
  }
}
.collapsible__header {
  margin: 0 auto 4rem;
  text-align: center;
}
.collapsible__body {
  @media screen and (min-width: $media-breakpoint-600px) {
    display: flex;
    margin: 4rem auto;
    justify-content: space-between;
    > * {
      flex: 1;
      margin: 0 2rem;
    }
    > *:first-child {
      margin-left: 0;
    }
    > *:last-child {
      margin-right: 0;
    }
  }
}
.collapsible__controls {
  display: flex;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
}
.state--show_search_results .collapsible__background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.4);
  z-index: 1;
}

.marketing__container {
  display: flex;
  width: 100%;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
}

.marketing__wrapper {
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  // max-width: 400px;
  border: 2px dotted #eee;
  > label {
    line-height: 1.5;
    color: white;
    margin-left: 2rem;
  }
}