.partnership {}
.partnership__content {
  display: flex;
  align-items: center;
  > * {
    flex: 1;
  }
  h2 {
    line-height: 2rem;
  }
  > p {
    // flex: 0.7;
    width: auto;
    font-size: 2.6rem;
    font-weight: 600;
    color: black;
    // background: $devbg1;
  }
  @media screen and (min-width: $media-breakpoint-500px) {
    h2 {
      line-height: 2.5rem;
    }
  }
}
.partnership__logo-link {
  display: inline-block;
  max-width: 160px;
}
.partnership__logo-link:first-child {
  max-width: 140px;
  margin-right: 2rem;
}
.partnership__logo-link:last-child {
  margin-left: 2rem;
}
.partnership__link {
  transition: opacity 0.2s;
  &.fade-out {
    opacity: 0;
  }
}
.partnership__link > p {
  padding-right: 2rem;
}
