@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&subset=latin-ext');

html {
  font-family: $font-family;
  font-size: $reset-font-size;
}
body {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: $text-color;

  @media screen and (min-width: $media-breakpoint-500px) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}
h1,h2,h3,h4 {
  margin: 0.5rem 0;
  color: $title-color;
}
h1 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 2.7rem;
}
h2 {
  font-weight: 400;
}
h3 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.9rem;
}
a {
  color: $primary-color;
}
.text-centered {
  text-align: center;
}
.text-italic {
  font-style: italic;
}
ul {
  margin: 0;
  margin-left: 2rem;
  padding: 0;
}
li {
  text-align: left;
}
