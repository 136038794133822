#partners {
  // background-color: $devbg1;

  .partners__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 2rem 2rem 0;
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 6px;
  }
  .partner__body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin: 2rem;
    // background-color: $devbg3;

    a {
      margin: 0 auto;
      width: 100px;
      // align-self: baseline;
      // background-color: $devbg2;
    }
    img {
      width: 100%;
    }
    p {
      font-size: 1.3rem;
      font-weight: 600;
      color: black;
      white-space:nowrap;
      text-align: center;
      // background-color: $devbg1;
    }
  }
  .logo__di {
    a { width: 45%; }
    @media screen and (min-width: $media-breakpoint-600px) {
      a { width: 50%; }
    }
  }
  .logo__billigselskab {
    a { width: 100% }
    @media screen and (min-width: $media-breakpoint-600px) {
      // img { width: 55%; }
    }
  }
  .logo__itb {
    a { width: 60%; }
  }
  .logo__if {
    a { width: 100%; }
    @media screen and (min-width: $media-breakpoint-600px) {
      animation-delay: { width: 100%; }
    }
  }
}
