.payment-feedback {
  .collapsible__body {
    margin: 0 auto;
    max-width: 60rem;
  }
  h2 {
    margin: 0 auto 4rem;
    color: white;
    text-align: center;
  }
  p {
    margin: 2rem 0;
    color: white;
    text-align: center;
  }
  a {
    color: white;
    text-decoration: none;
  }
}
