// material-icons
.mui-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid $line-color-light;
  border-radius: 50%;
  border-color: $primary-color;
  > i {
    padding: 0.7rem;
    align-self: center;
    font-size: 3rem;
    font-weight: 200;
    text-align: center;
    color: $primary-color;
  }
}
