.menu,
.menu__item {
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    text-decoration: none;
  }
}
.menu--horizontal {
  display: flex;
  justify-content: space-between;
  > li {
    flex: 1;
  }
}
