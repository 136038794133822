.btn {
  padding: 1.4rem 2.8rem;
  border-radius: 30px;
  border: none;
  &:focus {
    outline: transparent;
    outline-color: transparent;
  }
}
.btn-primary {
  background-color: $primary-color;
  color: white;
}
.btn-inverse {
  background-color: white;
  color: $primary-color;
}
.btn-outlined {
  border: 1px solid $primary-color;
  background-color: transparent;
  color: $primary-color;
}
.btn-outlined.btn-inverse {
  border-color: white;
  color: white;
}
.btn-error {
  background-color: red;
  color: white;
}
.btn-disabled {
  opacity: 0.7;
}
// TODO: implement disabled for buttons
button:not([disabled]) {
  cursor: pointer;
}
.mui-container {
  cursor: pointer;
}
