.process {
  display: flex;
}
.process__step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 11rem;
  height: 11rem;
  border: 2px solid $primary-color;
  border-radius: 50%;
  text-align: center;
  @media screen and (min-width: $media-breakpoint-500px) {
    width: 13rem;
    height: 13rem;
  }
}
.process__label {
  margin: 0.4rem auto;
  padding: 0;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  @media screen and (min-width: $media-breakpoint-500px) {
    margin: 0.6rem auto;
    font-size: 1.1rem;
    line-height: 1.3rem;
  }
}
.process__icon {
  padding: 0;
  font-size: 4rem;
  @media screen and (min-width: $media-breakpoint-500px) {
    font-size: 4.5rem;
  }
}
.process__line {
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 auto;
  width: auto;

  > span {
    width: 100%;
    height: 1px;
    border-top: 1.5px dashed $line-color-light;
  }
}
