.site-footer {
  padding: 1rem 0;
  background-color: #313131;
  color: white;
}
.site-footer__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: $media-breakpoint-900px) {
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    max-width: 1000px;  
  }
}
.site-footer__right {
  @media screen and (min-width: $media-breakpoint-900px) {
    // display: flex;
  }
}
.site-footer__logo {
  // background-color: $devbg2;
  > a {
    display: inline-block;
    padding: 3rem 2rem 0;
    max-width: 140px;
    // background-color: $devbg1;
  }
  @media screen and (min-width: $media-breakpoint-500px) {
    margin: 0 auto;
    padding: 0 2rem;
  }
  @media screen and (min-width: $media-breakpoint-900px) {
    a {
      padding: 1rem 2rem;
      max-width: 180px;
    }
  }
}
.site-footer__nav {
  margin-bottom: 1rem;
  padding: 2rem;
  width: 100%;
  // background-color: $devbg3;
  .menu--horizontal {
    flex-direction: column;
  }
  a {
    padding: 1rem 0;
    font-size: 1.4rem;
    color: white;
  }
  @media screen and (min-width: $media-breakpoint-500px) {
    .menu--horizontal {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > li {
        flex: auto;
        text-align: center;
      }
    }
  }
}
.site-footer__social  ul {
  display: flex;
  margin: 0 auto;
  max-width: 200px;
  flex-direction: row;
  justify-content: space-between;
  a {
    color: white;
  }
  i {
    font-size: 3.5rem;
  }
}
.site-footer__address {
  padding: 2rem 0;
  text-align: center;
  p {
    margin: 0.5rem auto;
  }
  @media screen and (min-width: $media-breakpoint-500px) {
    p {
      font-size: 1.2rem;
    }
  }
}
