.collapsible--inverse {
  // border-top: 1px solid red;
  z-index: 100;
  .line {
    display: block;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid white;
  }
  .collapsible__panel {
    background-color: $primary-color;
  }
  .collapsible__body {
    display: inherit;

    // background-color: $devbg1;

    .form-group {
      @media only screen and (max-width: 600px) {
        display: block;
        margin-bottom: 1rem;
      }
      @media only screen and (min-width: 600px) {
        margin: 4rem auto;
      }
    }
    .invisible_field {
      visibility: hidden
    }
    .form__control:first-child {
      @media only screen and (min-width: 600px) {
        margin-right: 2rem;
      }
      @media only screen and (max-width: 600px) {
        margin-bottom: 2rem;
      }
    }
    .form__control:last-child {
      @media only screen and (min-width: 600px) {
        margin-left: 2rem;
      }
      @media only screen and (max-width: 600px) {
        margin-bottom: 2rem;
      }
    }
  }
  .collapsible__controls {
    // margin: 4rem auto;
    .btn {
      margin: 2rem 1rem;
      padding-left: 8rem;
      padding-right: 8rem;
    }
  }
}
.ElementsApp,
.ElementsApp .InputElement {
  color: white !important;
}
.stripe-card-element {
  padding: 1rem 0;
  border-bottom: 1px solid white;
}
.StripeElement {
  .form-group *:first-child,
  .form-group *:last-child {
    margin-left: 0 !important;
    margin-right: 0;
  }
}
