.accordion {
  border: none !important;
}
.accordion__item {
  border-top: none !important;
}

.risika-kreditscore {
  display: flex;
  > * {
    flex: 1;
  }
}
.risika-kreditscore__score {
  display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: center;

  img {
    margin: 0 auto;
    max-width: 170px;
    align-self: center;
  }
}
.accordion__title {
  display: flex;
  align-items: center;

  h3 {
    padding-right: 1rem;
    font-size: 1.6rem;
    font-weight: 700;
    white-space: nowrap;
    text-transform: uppercase;
  }
  .line {
    border-bottom: 1px solid $line-color-light;
  }
  .mui-container {
    > i {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
}
.accordion__body {
  p {
    text-align: left;
  }
}
.risika-kreditscore__panel {
  margin-bottom: 0.2rem;
  padding: 1rem;
  font-size: 1.8rem;
  line-height: 1rem;
  background: $secondary-color;
  color: white;

  p {
    text-align: center;
  }

  small {
    font-size: 1.2rem;
    color: rgba(255,255,255,0.7);
  }
}
